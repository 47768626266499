import _defineProperty from "@babel/runtime/helpers/defineProperty";
jQuery.noConflict();

(function ($) {
  $(function () {
    var slickObjs = [];
    $('#menu-toggle').on('click', function () {
      $('body').toggleClass('menu-active');
    });
    $('#adjSwitch').on('click', function () {
      $.fn.fullpage.destroy('all');
    }); // fade in grid items

    if ($('.grid-section').length) {
      $('.grid-section').imagesLoaded({
        background: true
      }, function () {
        $('body').addClass('loaded');
        $('.grid-item').each(function (i, item) {
          setTimeout(function () {
            $(item).addClass('active');
          }, 150 * i);
        });
      });
    }

    if ($('.video-modal')) {
      var videoSrc = '';
      $('.video-modal').on('show.bs.modal', function (e) {
        console.log(e);
        videoSrc = $(e.relatedTarget).data('video-src').split('?')[0];
        $('.video', e.currentTarget).attr('src', videoSrc + '?autoplay=1&amp;modestbranding=1&amp;showinfo=0');
      }); // stop playing the youtube video when I close the modal

      $('.video-modal').on('hide.bs.modal', function (e) {
        // a poor man's stop video
        setTimeout(function () {
          $('.video', e.currentTarget).attr('src', '');
          videoSrc = '';
        }, 300);
      });
    } // fade in images


    if ($('.image-and-text-image').length) {
      $('.image-and-text-image').each(function (i, image) {
        $('.image-and-text-image-content', image).imagesLoaded({
          background: true
        }, function () {
          $(image).addClass('active');
        });
      });
    } // if($('.instagram-section').length) {
    //   get_instagram(1);
    //   $('.more-instagram').on('click', function(e){
    //     e.preventDefault();
    //     instaToggle = 0;
    //     get_instagram(0);
    //   });
    // }


    var instaToggle = 0;

    function get_instagram(start) {
      var instaCount = 5;

      if ($(window).width() < 768) {
        instaCount = 6;
      } else if ($(window).width() >= 768 && $(window).width() < 1024) {
        instaCount = 4;
      }

      var lastID = $('.more-instagram').data('lastid');
      var instaurl = "https://api.instagram.com/v1/users/self/media/recent/?access_token=1394562082.3f6633e.3090856d45594ef99d0db1cf7e42d80c&count=".concat(instaCount);

      if (lastID) {
        instaurl += "&max_id=".concat(lastID);
      }

      $.ajax({
        method: "GET",
        url: instaurl,
        success: function success(data) {
          $.when.apply($, $('.new-insta').map(function (i, item) {
            $(item).slideDown(300, function () {
              $(item).removeClass('.new-insta');
            });
          })).then(function () {
            // all ajax calls done now
            $.each(data.data, function (i, item) {
              if (i === data.data.length - 1) {
                $('.more-instagram').data('lastid', item.id);
              }

              var instagramUrl = item.link;
              var imageUrl = item.images.low_resolution.url;
              var itemType = item.type;

              if (itemType == 'image') {
                if (typeof item.carousel_media != 'undefined') {
                  itemType = 'carousel';
                }
              }

              $('.instagram-posts').append("\n                    <div class=\"image-link new-insta insta-post-".concat(itemType, "\">\n                      <href=\"").concat(instagramUrl, "\" target=\"_blank\" class=\"instagram-img-container\">\n                        <div class=\"instagram-img bg-centered\" style=\"background-image: url(").concat(imageUrl, ")\">\n                        </div>\n                      </a>\n                    </div>"));
            });

            if (start === 1) {
              instaToggle = 1;
              get_instagram(0);
            }
          }); //
          // if($('.new-insta').length && instaToggle === 0 || start) {
          //   $('.new-insta').slideDown(300, function() {
          //     $('.new-insta').removeClass('new-insta');
          //   });
          // }

          if ($('.new-insta').length === 0) {
            $('.more-instagram').slideUp();
          }
        }
      });
    }

    function append_insta(data) {}

    if ($('.video_section').length) {
      var $videoPlayer = $('video');
      console.log($videoPlayer);
      $('.audio-toggle').on('click', function () {
        console.log($($videoPlayer).prop('muted'));

        if ($($videoPlayer).prop('muted') === false) {
          $($videoPlayer).prop('muted', true);
          $('.audio-on').hide();
        } else {
          $($videoPlayer).prop('muted', false);
          $('.audio-on').show();
        }
      });
    }

    var IsInViewport = function IsInViewport(el) {
      if (typeof jQuery === "function" && el instanceof jQuery) el = el[0];
      var rect = el.getBoundingClientRect();
      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    };

    if ($('.full_width_carousel').length) {
      $('.full_width_carousel').imagesLoaded({
        background: true
      }, function () {
        if (!$('body').hasClass('mobile')) {
          $('.down-arrow').on('click', function () {
            $("html, body").animate({
              scrollTop: $(window).height() - 55 + 'px'
            }, 300);
          });
          $('.full_width_carousel').each(function (i, carousel) {
            var _slickArgs;

            var autoFade = $('.carousel-images', carousel).data('autofade') === 1 ? true : false;
            var hasDots = $('.carousel-images', carousel).data('dots') === 1 ? true : false;
            var hasArrows = $('.carousel-images', carousel).data('arrows') === 1 ? true : false;
            var navRef = $('.carousel-images', carousel).data('nav') != 0 ? $('.carousel-images', carousel).data('nav') : false;
            var slickArgs = (_slickArgs = {
              'arrows': true,
              'slidesToShow': 1,
              'slidesToScroll': 1,
              'autoplay': autoFade,
              'autoplaySpeed': 3000,
              'speed': hasDots ? 500 : 2000,
              'pauseOnHover': false,
              'fade': true
            }, _defineProperty(_slickArgs, "arrows", hasArrows), _defineProperty(_slickArgs, 'dots', hasDots), _defineProperty(_slickArgs, 'infinite', true), _defineProperty(_slickArgs, 'prevArrow', $('.carousel-arrow-left', carousel)), _defineProperty(_slickArgs, 'nextArrow', $('.carousel-arrow-right', carousel)), _slickArgs); // if(navRef) {
            //   slickArgs.asNavFor = navRef;
            // }

            var $slickInstance = $('.carousel-images', carousel).on('init', function (slick) {
              setTimeout(function () {
                $('.slick-slider', carousel).addClass('slick-fadein');
                setTimeout(function () {
                  $('.section-loader').fadeOut();
                }, 500);
              }, 500);
            }).slick(slickArgs); //slickObjs.push(['slickinstance' => $slickInstance, 'slickindex' => i]);
          });
        } else {
          $('.section-loader').fadeOut();
        }
      });
    } // if($('.portfolio-slides').length) {
    //   var $slickInstance = $('.portfolio-slides')
    //   .on('init', function(slick) {
    //     setTimeout(function() {
    //       $('.portfolio-slides.slick-slider').addClass('slick-fadein');
    //       setTimeout(function() {
    //         $('.section-loader').fadeOut();
    //       }, 500)
    //     },500);
    //   })
    //   .slick({
    //     'arrows': true,
    //     'slidesToShow': 1,
    //     'slidesToScroll': 1,
    //     'autoplay': false,
    //     'pauseOnHover': false,
    //     'fade': true,
    //     'arrows': false,
    //     'dots': false,
    //     'infinite': true,
    //     'draggable': false,
    //     'adaptiveHeight': true
    //   });
    // }


    if ($('.fancybox').length) {
      $(".fancybox").fancybox({});
    } //if(!$('body').hasClass('logged-in')) {


    fullpageFunctions(); //}

    function fullpageFunctions() {
      if ($('#fullpage').length) {
        $('#fullpage').fullpage({
          //anchors: '',
          //menu: '#anchor-menu',
          loopHorizontal: false,
          scrollOverflow: true,
          sectionSelector: '.page-section',
          onLeave: function onLeave(index, nextIndex, direction) {
            if ($('body').hasClass('menu-active')) {
              $('body').toggleClass('menu-active');
            }

            var $nextSection = $('.fp-section').eq(nextIndex - 1); // if($($nextSection).data('type') === 'video_section') {
            //   var $videoPlayer = $('video', $nextSection);
            //   $videoPlayer[0].player.play();
            // }
            // var sectionSlug = $('.fp-section').eq(nextIndex).data('anchor');
            // if($('.fp-section.active .fp-scrollable').length) {
            //   var IScroll = $('.fp-section.active').find('.fp-scrollable').data('iscrollInstance');
            //   IScroll.scrollTo(0, 0, 0);
            // }
          },
          afterLoad: function afterLoad(origin, destination, direction) {
            $('body').addClass('has-fullpage');
          }
        });
      }
    }
  });
})(jQuery);